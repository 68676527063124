<template>
  <div>
  <div>
    <SomeIcon class="icon" />
  </div>
  <h3>ê.se är en portal till din plånbok för kryptovalutor</h3>
  <p>Logga in uppe till höger och använd plånboken från <a href="https://docs.tor.us/wallet/what-is-wallet" target="_blank">Torus</a></p>
  <p>Du kan enkelt köpa kryptovaluta (Fiat till krypto), föra över till andra m.m.</p>
  <h3>Hur fungerar t.ex. Ethereum?</h3>
  <p>Ethereum är en blockchain-baserad plattform som syftar till att göra det lättare att skapa applikationer som inte hanteras eller kontrolleras av en enhet. Istället styrs de av kod.</p>
  </div>
</template>

<style scoped>
ul {
  list-style: none;
  display: flex;
  padding: 0;
}
li {
  padding: 5px 15px 5px 0;
}
li a {
  text-decoration: none;
  color: black;
}
li a:hover {
  color: #404040;
}
</style>

<script>
import Torus from '@toruslabs/torus-embed'
import SomeIcon from './assets/Device.svg';
export default {
  mounted() {
    this.$nextTick(function () {
            const torus = new Torus({buttonPosition:"top-right"});
            torus.init({
              // Example white-labeling configurations
              whiteLabel: {
                theme: {
                  isDark: false,
                  colors: {
                    torusBrand1: "#282c34",
                  },
                },
                logoDark: "https://xn--bda.se/Device.png", // Dark logo for light background
                logoLight: "https://xn--bda.se/Device.png", // Light logo for dark background
                topupHide: false,
                featuredBillboardHide: true,
                disclaimerHide: true,
                defaultLanguage: "en",
              },
            });

        // ...
      });
  },
  data: () => ({
  }),
  components:{
      SomeIcon,
  }
};
</script>