var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [_c("SomeIcon", { staticClass: "icon" })], 1),
    _vm._v(" "),
    _c("h3", [_vm._v("ê.se är en portal till din plånbok för kryptovalutor")]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Du kan enkelt köpa kryptovaluta (Fiat till krypto), föra över till andra m.m."
      )
    ]),
    _vm._v(" "),
    _c("h3", [_vm._v("Hur fungerar t.ex. Ethereum?")]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Ethereum är en blockchain-baserad plattform som syftar till att göra det lättare att skapa applikationer som inte hanteras eller kontrolleras av en enhet. Istället styrs de av kod."
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Logga in uppe till höger och använd plånboken från "),
      _c(
        "a",
        {
          attrs: {
            href: "https://docs.tor.us/wallet/what-is-wallet",
            target: "_blank"
          }
        },
        [_vm._v("Torus")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }